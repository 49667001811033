import SliderManager from "./slider/sliderManager";

export default class SuggestedChollosSliderManager {

    constructor() {
        this.sliderManager = new SliderManager();
        this.eventDelegation();
    }

    eventDelegation() {
        window.addEventListener('load', () => {
            this.registerSlider();
        });
    }

    registerSlider() {
        let slider = document.querySelector('[data-entity="slider"]');
        if (!slider) return;
        this.sliderManager.addSlider('[data-entity="slider"]', null, null);
        slider.querySelector('.swiper-wrapper').classList.remove('visibility-hidden');
    }

}