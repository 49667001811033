import ScrollManager from "@web/vpt-assets/assets/js/utilities/scrollManager";

export default class GroupListPrinter {
    constructor() {
        this.scrollManager = new ScrollManager();
        this.bannerElementsById = [];
        this.firstPrint = true;
        this.groupTypes = {
            onSale: {
                container: '[data-container="groups-on-sale"]',
                numGroups: 0,
                hasBanners: true
            },
            comingSoon: {
                container: '[data-container="groups-coming-soon"]',
                numGroups: 0,
                hasBanners: false
            },
            extra: {
                container: '[data-container="groups-extra"]',
                numGroups: 0,
                hasBanners: false
            },
        }
        this._initializeView();
    }

    _initializeView() {
        document.querySelectorAll('[data-banner]').forEach((bannerElement) => {
            this.bannerElementsById[bannerElement.dataset.position] = bannerElement;
        });
    }

    updateGroupDistance(group) {
        if (!group.container || !group.distance) {
            return;
        }
        group.container.querySelector('[data-km-distance]').innerHTML = group.distance + ' km';
        group.container.querySelector('[data-container="distance"]').classList.remove('hidden');
    }

    printGroups(groups) {
        if (this.firstPrint) {
            this._removeUnusedContainers();
            this.firstPrint = false;
        }

        this._resetGroupTypes();
        let group, groupType, groupsContainer, groupTypeContainer;

        for (let i in groups) {
            group = groups[i];
            groupType = this.groupTypes[group.type];
            groupsContainer = document.querySelector(groupType.container + ' [data-container="groups"]');

            if (groupType.hasBanners && this.bannerElementsById[groupType.numGroups]) {
                groupsContainer.appendChild(this.bannerElementsById[groupType.numGroups]);
            }

            groupsContainer.appendChild(group.container);
            if (groupType.numGroups == 0) {
                groupTypeContainer = document.querySelector(groupType.container);
                groupTypeContainer.style.display = '';
            }
            groupType.numGroups++;
        }

        this.scrollManager.scrollTo('#central_content', () => {}, 0, true, 100);
    }

    _removeUnusedContainers() {
        document.querySelectorAll('[data-category]').forEach((container) => {
            container.remove()
        });

        document.querySelectorAll('[data-not-sortable]').forEach((container) => {
            container.remove()
        });
    }

    _resetGroupTypes() {
        let groupTypeContainer;

        Object.entries(this.groupTypes).forEach(([i, groupType]) => {
            groupTypeContainer = document.querySelector(groupType.container);
            if (!groupTypeContainer) {
                return;
            }
            groupTypeContainer.querySelector('[data-container="groups"]').innerHTML = '';
            this.groupTypes[i].numGroups = 0;
            groupTypeContainer.style.display = 'none';
        });
    }
}