import Group from "./group";

export default class GroupList {
    constructor() {
        this.groupList = [];
        this._generateGroupList();
    }

    _generateGroupList() {
        let group;
        document.querySelectorAll('[data-container-list="groups"] [data-entity="group"]').forEach((element) => {
            group = new Group(
                element.dataset.id,
                element.dataset.latitude,
                element.dataset.longitude,
                element.dataset.price,
                element.dataset.filterPrice,
                element.dataset.filterAccommodationcategory,
                element.dataset.rate,
                element.dataset.filterRating,
                element.dataset.time,
                element.dataset.type,
                element.dataset.tags.split(';').map(elem=> parseInt(elem)),
                element.dataset.filterAllowpets ?? false,
                element
            );
            this.groupList.push(group)
        });
    }

    getList() {
        return this.groupList;
    }
}