export default class MapsLoader {
    getOptions() {
        const options = {
            center: {lat: 0, lng: 0},
            zoom: 3,
            key: myApp.config.parameters.googleMapsApiKey,
            v: "weekly",
            libraries: ["geometry", "marker", "cluster"],
            mapId: myApp.config.parameters.googleMapsId,
            gestureHandling: 'greedy',
            clickableIcons: false,
            fullscreenControl: false,
        }

        if (myApp.deviceDetector.isXsScreen()) {
            options.zoomControlOptions = {position: google.maps.ControlPosition.LEFT_TOP};
            options.streetViewControlOptions = {position: google.maps.ControlPosition.LEFT_TOP};
        } else {
            options.mapTypeControlOptions = {position: google.maps.ControlPosition.BOTTOM_LEFT};
        }

        return options;
    }
}
