import GroupList from "./groupList";
import GroupListFilter from "./groupListFilter/groupListFilter";
import GroupListPrinter from "./grupListPrinter";
import GroupListSorter from "./groupListSorter";
import GroupListMap from "./groupListMap";

export default class GroupListManager {
    constructor() {
        this.maxDelayTime = 300;
        this.groupList = new GroupList();
        this.groupListFilter = new GroupListFilter(this.printGroups.bind(this));
        this.groupListSorter = new GroupListSorter(this.printGroups.bind(this), this.updateGroupsDistance.bind(this));
        this.groupListPrinter = new GroupListPrinter();
        this.groupListMap = new GroupListMap();
        let printGroupsOnFilter = this.groupListFilter.initializeFilters();
        let printGroupsOnSort = this.groupListSorter.initializeSorter();

        this.groupListFilter.initializeCategories();

        if (printGroupsOnFilter || printGroupsOnSort) {
            this.printGroups();
        } else {
            this.groupListFilter.updateFilterView(this.groupList.getList(), this.groupList.getList());
        }
        this.eventDelegation();
    }

    eventDelegation() {
        const modalGroupMap = $('#modal_group_map');
        modalGroupMap.on('custom-modal-show', () => {
            this.groupListMap.initialize(this.groupList.getList());
        });
        modalGroupMap.on('custom-modal-hidden', () => {
            myApp.urlManager.removeUrlParam('openMap');
        });
    }

    updateGroupsDistance(userLocation) {
        this.groupList.getList().forEach((group) => {
            group.calculateDistance(userLocation);
            this.groupListPrinter.updateGroupDistance(group);
        });
    }

    printGroups() {
        const start = Date.now();

        const groupsContainer = document.querySelector('[data-container-list="groups"]');
        groupsContainer.classList.add('container-disabled')
        let groups = this.groupListFilter.filter(this.groupList.getList());
        groups = this.groupListSorter.sort(groups);
        this.groupListPrinter.printGroups(groups);
        this.groupListFilter.updateFilterView(groups, this.groupList.getList());
        this.groupListSorter.updateSorterView();
        const end = Date.now();
        let totalTime = end - start;
        let timeOutTime = (totalTime > this.maxDelayTime) ? 0 : (this.maxDelayTime - totalTime);

        setTimeout(() => {
            groupsContainer.classList.remove('container-disabled')
        }, timeOutTime);
    }
}