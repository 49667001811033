import GroupValueCalculator from "../../groupValueCalculator";
import CriteriaList from "./criteriaList";
import Criteria from "./criteria";

export default class GroupCriteriaTypeFilter {
    constructor() {
        this.groupValueCalculator = new GroupValueCalculator()
    }

    isFiltered(criteriaList, criteria, group) {
        this.criteriaList = criteriaList;
        let criteriaChecked = criteriaList.getCriteriaChecked(criteria.name);
        let numFilteredCriteriaType = 0;
        let criteriaFiltered;

        for (let criteriaTmp of criteriaChecked) {
            criteriaFiltered = this._checkCriteria(group, criteriaTmp);
            if (criteriaFiltered) {
                numFilteredCriteriaType++;
            }
        }

        if (criteria.operationType === Criteria.typeOr) {
            return numFilteredCriteriaType > 0;
        } else {
            return numFilteredCriteriaType === criteriaChecked.length;
        }
    }

    _checkCriteria(group, criteria) {
        let valueToFilter = this._getValueToFilter(criteria, group);
        return criteria.eval(valueToFilter);
    }

    _getValueToFilter(criteria, group) {
        let valueToFilter;

        switch (criteria.name) {
            case CriteriaList.typePrice:
                valueToFilter = this.groupValueCalculator.getFilterPriceValue(group);
                break;
            case CriteriaList.typeAccommodationCategory:
                valueToFilter = this.groupValueCalculator.getAccommodationCategoryValue(group);
                break;
            case CriteriaList.typeRate:
                valueToFilter = this.groupValueCalculator.getFilterRateValue(group);
                break;
            case CriteriaList.typeFreeCancellation:
            case CriteriaList.typeMealPlan:
            case CriteriaList.typeFeaturedDates:
            case CriteriaList.typeThematic:
            case CriteriaList.typeLocation:
                valueToFilter = this.groupValueCalculator.getTagValue(group, criteria.valueA);
                break;
            case CriteriaList.typeAllowPets:
                valueToFilter = this.groupValueCalculator.getAllowPetsValue(group);
                break;
        }

        return valueToFilter;
    }
}