import CriteriaList from "./criteria/criteriaList";
import GroupCriteriaTypeFilter from "./criteria/groupCriteriaTypeFilter";
import FilterPrinter from "./filterPrinter";
import Criteria from "./criteria/criteria";
import DataLayerManager from "../../trackers/dataLayer/dataLayerManager";

export default class GroupListFilter {
    constructor(onFilterFunction) {
        this._criteriaList = new CriteriaList();
        this.dataLayerManager = new DataLayerManager();
        this.groupCriteriaTypeFilter = new GroupCriteriaTypeFilter();
        this.filterPrinter = new FilterPrinter(this._criteriaList, onFilterFunction);
        this.onFilterFunction = onFilterFunction;
    }

    initializeFilters() {
        document.querySelectorAll('[data-action="filter"]').forEach((element) => {
            let criteria = this._criteriaList.initializeCriteria(element);
            if (!criteria) {
                return;
            }
            element.addEventListener('change', (currentElement) => {
                this._onFilter(criteria, currentElement);
            });
        });
        document.querySelectorAll('[data-action="reset-filters"]').forEach((element) => {
            element.addEventListener('click', () => {
                this._resetFilters();
            });
        });

        return this.filterPrinter.initializeFilters();
    }

    filter(groups) {
        return groups.filter(group => this._isFiltered(group, true));
    }

    _onFilter(criteria, currentElement) {
        let checked = currentElement.currentTarget.checked;

        let data = {
            event: "filter_interaction",
            event_category: "filter",
            event_detail_1: criteria.name,
            event_detail_2: criteria.text,
            event_detail_3: checked ? 'check' : 'uncheck'
        };

        this.dataLayerManager.pushData(JSON.stringify(data));
        this._criteriaList.setCriteria(criteria.name, criteria.valueA, criteria.valueB, checked);
        this.onFilterFunction();
    }

    updateFilterView(filteredGroups, groups) {
        for (let criteria of this._criteriaList.criterias) {
            this._setNumGroupsFiltered(criteria, groups);
            this.filterPrinter.setCriteriaVisibility(criteria);
        }

        this.filterPrinter.updateFilterView(filteredGroups, groups);
    }

    initializeCategories() {
        this.filterPrinter._updateFilterCategories()
    }

    _setNumGroupsFiltered(criteria, groups) {
        this._deactivateAllCategoryCriteria(criteria);
        let numGroups = 0;

        for (let group of groups) {
            if (!this.groupCriteriaTypeFilter._checkCriteria(group, criteria)) {
                continue;
            }
            if (this._isFiltered(group, false)) {
                numGroups++;
            }
        }
        this._resetCategoryCriteria(criteria);
        criteria.numItems = (numGroups > 0 || criteria.checked) ? numGroups : 0;
    }

    _deactivateAllCategoryCriteria(criteria) {
        for (let criteriaTmp of this._criteriaList.getCriteriaByName(criteria.name)) {
            if (criteriaTmp.checked) {
                criteriaTmp.checkedTmp = true;
            } else {
                criteriaTmp.checkedTmp = false;
            }

            if (criteriaTmp === criteria) {
                criteriaTmp.checked = true;
            } else {
                if (criteriaTmp.operationType === Criteria.typeOr) {
                    criteriaTmp.checked = false;
                }
            }
        }
    }

    _resetCategoryCriteria(criteria) {
        for (let criteriaTmp of this._criteriaList.getCriteriaByName(criteria.name)) {
            criteriaTmp.checked = criteriaTmp.checkedTmp;
        }
    }

    _isFilteredCounter(group, criteria) {
        let checkGroupCriteriaTmp, criteriaCheckedTmp;
        let criteriaChecked = this._criteriaList.getCriteriaChecked();

        for (let criteriaTmp of criteriaChecked) {

            checkGroupCriteriaTmp = this.groupCriteriaTypeFilter._checkCriteria(group, criteriaTmp)
            if (!checkGroupCriteriaTmp && criteriaTmp.name != criteria.name) {
                criteriaCheckedTmp = this._criteriaList.getCriteriaChecked(criteriaTmp.name);

                for (let criteriaTmp1 of criteriaCheckedTmp) {
                    if (this.groupCriteriaTypeFilter._checkCriteria(group, criteriaTmp1)) {
                        return true;
                    }
                }
                return false;
            }
        }
        return true;
    }

    _isFiltered(group, modifyVisibility = false) {
        let criteriaActive = this._criteriaList.getCriteriaChecked();
        let isFiltered = true;
        for (let criteria of criteriaActive) {
            if (!this.groupCriteriaTypeFilter.isFiltered(this._criteriaList, criteria, group)) {
                isFiltered = false;
                break;
            }
        }
        if (modifyVisibility) {
            group.visible = (criteriaActive.length === 0 ? true : isFiltered);
        }
        return criteriaActive.length === 0 ? true : isFiltered;
    }

    _resetFilters() {
        this._criteriaList.cleanCriteria();

        document.querySelectorAll('[data-action="filter"]').forEach((element) => {
            element.checked = false;
        });
        this.onFilterFunction();
    }
}