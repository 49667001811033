export default class Chollo
{
    constructor()
    {
        this.id = null;
        this.price = null;
        this.latitude = null;
        this.longitude = null;
        this.distance = 100000000;
        this.rate = null;
        this.route = 0;
        this.dateId = null;
        this.mealPlanTag = 0;
    }
}
