export default class CriteriaType {

    constructor(type) {
        this.type = type;
    }
    toEvalString(valueToFilter, valueA, valueB) {
        let valorA = !isNaN(valueA) ? parseFloat(`${valueA}`) : valueA;
        let valorB = !isNaN(valueB) ? parseFloat(`${valueB}`) : valueB;
        let valorToFilter = (!isNaN(valueToFilter) && !Array.isArray(valueToFilter)) ? parseFloat(`${valueToFilter}`) : valueToFilter;
        switch (this.type) {
            case CriteriaType.typeGreaterOrEqualThan:
                return valorA <= valorToFilter;
            case CriteriaType.typeLessOrEqualThan:
                return valorA >= valorToFilter;
            case CriteriaType.typeRange:
                return valorA <= valorToFilter && valorToFilter < valorB;
            case CriteriaType.typeEqual:
                return valorA == valorToFilter;
            case CriteriaType.typeIncludes:
                return valorToFilter.includes(valorA);
        }
    }
}

CriteriaType.typeGreaterOrEqualThan = 1;
CriteriaType.typeLessOrEqualThan = 2;
CriteriaType.typeRange = 3;
CriteriaType.typeEqual = 4;
CriteriaType.typeIncludes = 5;
