var ItemSorter = function (sortItemsArray) {

    "use strict";

    this.currentOrder = 'asc';
    this.sortItemsArray = sortItemsArray;

    this.sortAsc = function (sortBy) {
        this.currentOrder = 'asc';
        this.sortItemsArray.sort(function (a, b) {
            if (sortBy === 'time') {
                let bTime = b.time;
                if (b.class == "coming_soon"){
                    bTime = a.time;
                }
                return +a[sortBy] - bTime;
            }
            return +a[sortBy] - +b[sortBy];
        });
    };

    this.sortDsc = function (sortBy) {
        this.currentOrder = 'dsc';
        this.sortItemsArray.sort(function (a, b) {
            if (sortBy === 'time') {
                let aTime = a.time;
                if (a.class == "coming_soon"){
                    aTime = 0;
                }
                return +b[sortBy] - aTime;
            }
            return +b[sortBy] - +a[sortBy];
        });
    };
    
    this.sortByMultipleFields = function (field, method) {
        var reversed = (method == 'asc') ? 1 : -1;
        
        var sortable = [];
        for (var key in this.sortItemsArray) {
            if (this.sortItemsArray.hasOwnProperty(key)) {
                var entity = this.sortItemsArray[key];
                sortable.push([entity, entity[field]]);
            }
        }
        sortable.sort(function (a, b) {
            return reversed * (a[1][method] - b[1][method]);
        });
        
        var sortableList = [];
        $.each(sortable, function (key, value) {
            sortableList.push(value[0]);
        });
        
        this.sortItemsArray = sortableList;
    };

    this.getCurrentOrder = function () {
        return this.currentOrder;
    };
};

export default ItemSorter;