export class MapsPrinter {
    constructor() {
        this.urlParam = 'openMap';
        this.eventDelegation();
        this.onLoadOpenModalMap();
    }

    eventDelegation() {
        $('body').on('click', '[data-close-infowindow]', () => {
            $('[data-own-infowindow]').hide();
            myApp.urlManager.setUrlParam(this.urlParam, '');
        });

        $('body').on('click', '.map-container', () => {
            let infoWindow = $('[data-own-infowindow]');
            if (infoWindow.is(':visible')) {
                $('[data-own-infowindow]').hide();
            }
            if ($('.marker-selected').length == 0) {
                myApp.urlManager.setUrlParam(this.urlParam, '');
            }
        })

        $('body').on('click', '[data-element="modal-close"]', () => {
            let infoWindow = $('[data-own-infowindow]');
            if (infoWindow.is(':visible')) {
                infoWindow.hide();
            }
        });
    }

    onLoadOpenModalMap() {
        window.addEventListener('load', () => {
            if (myApp.urlManager.checkUrlParam(this.urlParam) && document.querySelector('#modal_group_map.custom-modal') != null) {
                myApp.customModalManager.openModal('#modal_group_map');
            }
        });
    }


    activateMarker(popupMarker, data) {
        this.showCustomInfoWindow(data);
        myApp.urlManager.setUrlParam(this.urlParam, popupMarker.id);
    }


    showCustomInfoWindow(data) {
        let ownInfoWindow = $('[data-own-infowindow]');
        ownInfoWindow.html(data);
        ownInfoWindow.find('[data-own-infowindow-image]').on('load', () => {
            this.showInfoWindowWithAnimation(ownInfoWindow);
        });
        myApp.collapseManager.initialize();
    };

    showInfoWindowWithAnimation(ownInfoWindow) {
        if (myApp.deviceDetector.isXsScreen() || myApp.deviceDetector.isSmScreen()) {
            ownInfoWindow.slideDown();
        } else {
            ownInfoWindow.fadeIn();
        }
    }

    showSkeletonInfoWindow() {
        let ownInfoWindow = $('[data-own-infowindow]');
        ownInfoWindow.hide();
        let infowindowSkeleton = $('#infowindow-skeleton-template').find('[data-target="skeleton-container"]').clone();
        ownInfoWindow.empty();
        ownInfoWindow.append(infowindowSkeleton);
        this.showInfoWindowWithAnimation(ownInfoWindow)
    };
}
