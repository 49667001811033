export default class Criteria {

    constructor(name, type, valueA, valueB = null, text = null) {
        this._name = name;
        this._type = type;
        this._valueA = valueA;
        this._valueB = valueB;
        this._checked = false;
        this._operationType = Criteria.typeOr;
        this._visible = true;
        this._text = text;
        this._numItems = 0
    }

    checkCriteria(filterName, valueA, valueB) {
        return filterName == this._name && valueA == this._valueA && (!this._valueB || valueB == this._valueB);
    }

    get name() {
        return this._name;
    }

    get type() {
        return this._type;
    }

    get valueA() {
        return this._valueA;
    }

    get valueB() {
        return this._valueB;
    }

    get checked() {
        return this._checked;
    }

    get operationType() {
        return this._operationType;
    }

    get visible() {
        return this._visible;
    }

    set checked(checked) {
        this._checked = checked;
    }

    set operationType(operationType) {
        this._operationType = operationType;
    }

    set visible(visible) {
        this._visible = visible;
    }

    get text() {
        return this._text;
    }

    set text(value) {
        this._text = value;
    }

    get numItems() {
        return this._numItems;
    }

    set numItems(value) {
        this._numItems = value;
    }

    eval(value) {
        return eval(this._type.toEvalString(value, this._valueA, this._valueB));
    }
}

Criteria.typeOr = 'or';
Criteria.typeAnd = 'and';
