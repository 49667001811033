export default class Group {
    constructor(id, latitude, longitude, price, filterPrice, accommodationCategory, rate, filterRate, time, type, tags, allowPets, container) {
        this._id = id;
        this._latitude = latitude;
        this._longitude = longitude;
        this._price = price;
        this._filterPrice = filterPrice;
        this._accommodationCategory = accommodationCategory;
        this._rate = rate;
        this._filterRate = filterRate;
        this._time = time;
        this._type = (type == 'coming_soon' ? 'comingSoon' : 'onSale');
        this._tags = tags;
        this._allowPets = allowPets;
        this._distance = null;
        this._visible = true;
        this._container = container;
    }

    calculateDistance(userLocation) {
        let lat1 = userLocation.latitude;
        let lon1 = userLocation.longitude;

        let lat2 = this._latitude;
        let lon2 = this._longitude;

        let radlat1 = Math.PI * lat1 / 180
        let radlat2 = Math.PI * lat2 / 180
        let theta = lon1 - lon2
        let radtheta = Math.PI * theta / 180
        let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        dist = Math.acos(dist)
        dist = dist * 180 / Math.PI
        dist = dist * 60 * 1.1515
        dist = dist * 1.609344;
        this._distance = dist.toFixed(2);
    }

    get id() {
        return this._id;
    }

    get latitude() {
        return this._latitude;
    }

    get longitude() {
        return this._longitude;
    }

    get price() {
        return this._price;
    }

    get accommodationCategory() {
        return this._accommodationCategory;
    }

    get rate() {
        return this._rate;
    }

    get filterRate() {
        return this._filterRate;
    }

    get tags() {
        return this._tags;
    }

    get allowPets() {
        return this._allowPets;
    }

    get time() {
        return this._time;
    }

    get distance() {
        return this._distance;
    }

    get filterPrice() {
        return this._filterPrice;
    }

    get visible() {
        return this._visible;
    }

    set visible(value) {
        this._visible = value;
    }

    get type() {
        return this._type;
    }

    get container() {
        return this._container;
    }

    set container(value) {
        this._container = value;
    }
}