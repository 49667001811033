export default class GroupValueCalculator {
    getFilterPriceValue(group) {
        return group.filterPrice;
    }

    getPriceValue(group) {
        return parseInt(group.price);
    }

    getAccommodationCategoryValue(group) {
        return group.accommodationCategory;
    }

    getTimeValue(group) {
        return group.time;
    }

    getRateValue(group) {
        return group.rate;
    }

    getFilterRateValue(group) {
        return group.filterRate;
    }

    getTagsValue(group) {
        return group.tags;
    }

    getTagValue(group, tagId) {
        tagId = parseInt(tagId);
        return group.tags.includes(tagId) ? tagId : null;
    }

    getAllowPetsValue(group) {
        return group.allowPets;
    }

    getDistanceValue(group) {
        return group.distance;
    }
}